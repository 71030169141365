.card .table tbody td{
    font-size: 14px;
}
.strpied-tabled-with-hover.add-edit-button-header.card{
    background: transparent !important;
}
.role-button-header{
    background: transparent !important;
}
#userRoleForm h3{
    padding-left: 20px;
}
#userRoleForm .col-md-4 > div{
    padding-left: 20px;
}
#userRoleForm .col-md-4 > div >input{
    font-size: 14px;
}
.section-title-userform{
    padding-left: 20px;
    font-size: 24px;
}
.switch-secion label{
    max-width: 100%!important;
}