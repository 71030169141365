.pull-right {
    float: right;
  }
.alert-message{
  margin-top: 15px;
  margin-bottom: 10px;
  /* padding: 10px; */
}

.alert-message-without-spacing{
  
}
fieldset {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px;
  width: 100%;
}
fieldset > legend{
  padding: 0 10px;
}

.form-group-container {
  margin-bottom: 20px;
}
/* @media (min-width: 992px){
  .card form [class*=col-]:first-child {
    padding-left: 20px;
    padding-right: 20px;
}
.card form [class*=col-] {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
} */
