.image-upload-container {
  position: relative;
}

.image-label {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
}

.profile-image {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.cross-button {
  position: absolute;
  top: 0px;
}
