.otp-form-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: center; /* Center-align items */
  }
  
  .instructions {
    flex: 1; 
    padding-right: 20px;
    text-align: left;
  }
  
  .qr-code-container {
    flex: 1; 
    text-align: center; 
  }

  .copy-icon {
    display: block; 
    max-width: fit-content;
    cursor: pointer;
    color: #007bff;
    border: 1px solid #007bff;
    padding: 3px 8px; 
    border-radius: 4px; 
    background-color: transparent; 
    transition: background-color 0.3s ease;
  }
  
  .copy-icon:hover {
    color: #0056b3; 
  }

  .code-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .backup-code-container{
    margin-top: 10px;
  }
  .copy-text{
    color:#3F72F7 ;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .copy-icon:active {
    background-color: #0056b3; 
    color: #ffffff; 
  }

 .instructions ol{
  /* padding-bottom: 10px; */
  list-style: disc;

 }
 .backup-code-container .backup-code-note{
  margin: 10px 0 10px;
  font-weight: 600;
 }

  @media (min-width: 1080px) {
    .otp-form-container {
      flex-direction: row; 
      justify-content: space-between; 
      align-items: center; 
    }
  
    .instructions {
      padding-right: 40px; 
    }
  
    .qr-code-container {
      text-align: center;
    }
  }