.login-form-conatiner{
  min-height: 81vh;
  padding: 30px 0 30px 0;
}
.custom-checkbox .form-check-input {
  margin-top: 3px;
  margin-right: 10px; /* Adding some space between the checkbox and the label */
}
#remember.form-check-input:focus{
clip-path: border-box;
  border: 1px solid black;
}
.error-message{
color: red;
}

.success-message{
  color: rgb(0, 255, 13);
}
.back-image{
background-image: url("../../assets/img/background.jpg");
z-index: -1
}
.user-fields-wrapper .form-control {
padding: 8px 8px;
}

.submit-btn{
background-color: #3472F7;
color: #ffffff;
font-size: 18px;
font-weight: 600;
}
.submit-btn.login-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.login-title{
font-size: 30px!important;
font-weight: 600!important;
margin: 0;
color: #333333!important;
}
.text-right > a{
color: #3472F7;
}

.outer-navbar{
background-color: #35383a;
}
.relative.min-w-0.break-words.shadow-lg.rounded-lg.border-0 {
background: #fff;
/* transform: translate(-50%,30%);
position: relative;
top: 50%;
left: 50%; */
}
.shadow {
box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}
.container-child{
  background: #fff;
}

.outer-layer .content > div {
  width: 100%;
}
.outer-layer .content > div > div {
  width: 100%;
}
.outer-layer .content > div > div > .container{
  max-width: 600px;
  width: 100%;
}
.form-label {
  width: 100%;
}