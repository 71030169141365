.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 81vh;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .not-found-message {
    font-size: 18px;
    color: #777;
  }
  