.no-float {
  float: none !important; /* !important ensures the rule takes precedence */
}

@media (min-width: 992px) {
    .no-float{
      float: none!important;
    }
}

.footer {
  /* position: sticky; */
  bottom: 0;
  width: 100%;
}
  