.pull-right {
  float: right;
}
.alert-message{
margin-top: 15px;
margin-bottom: 10px;
/* padding: 10px; */
}

.alert-message-without-spacing{

}

.card label{
color: #000000;
text-transform: none;
font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
font-size: 16px;
}
.card label:not(.role-permission-toggle){
  width: 100%;
}

.error-message{
font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  font-size: 14px;
}
.label-text{
  white-space: nowrap;
  overflow-y: clip;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);
  display: inline-block;
}
