.piechart-container-wrap{
    display: flex;
    justify-content: center;
}
.piechart-container{
    width: 100%;
}
.pie-chart-card-body{
    display: flex;
    justify-content: center;
    height: 300px;
}

.card .card-title.dashboard-element-title{
    font-weight: bolder;
    padding-left: 5px;
}

.card-stats .counter-card{
    min-height: 160px;
}

.card-stats .counter-card .icon-big{
   text-align: left!important;
}

.sim-expire-body,.green-profit-body{
    min-height: 600px;
    max-height: 600px;
    overflow: hidden;
}
.sim-expire-body .ant-table-wrapper,.green-profit-body .ant-table-wrapper{
    min-height: 550px;
    max-height: 550px;
    overflow: hidden;
}
/* .profit-chart-card .profitChartBody{
    height: 400px;
} */

.see-more-btn{
    color: #3f94fc;
}
/* .profitChartBody.card-body canvas{
    width: 100%!important;
} */
.dashboard-ant-picker{
    max-width: 300px!important;
    min-width: 300px!important;
}
.profit-chart-card .filter-col-wrapper.filter-column{
    flex-wrap: wrap;
}