.pull-right {
    float: right;
  }
.alert-message{
  margin-top: 15px;
  margin-bottom: 10px;
  /* padding: 10px; */
}

.alert-message-without-spacing{
  
}
fieldset {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px;
  width: 100%;
}
fieldset > legend{
  padding: 0 10px;
}

.form-group-container {
  margin-bottom: 20px;
}
.multiple-dropdown-selector .ant-select-selector{
  max-height: 40px;
  overflow-y: clip;
  
  }
.ant-select-multiple .ant-select-selection-overflow-item {
  align-self: center;
  max-width: 100%;
  width: auto;
}
