.pull-right {
    float: right;
  }
.alert-message{
  margin-top: 15px;
  margin-bottom: 10px;
  /* padding: 10px; */
}

.alert-message-without-spacing{
  
}
.index-column {
    width: 500px; /* Set the width for the "Index" column */
  }
  
  .status-column {
    width: 150px; /* Set the width for the "Status" column */
  }