body {
  font-family: "Roboto" !important;
}

.fixed-plugin .button-container .github-btn {
  display: inline-block;
  font-size: 14px;
}

/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/
.fixed-plugin .SocialMediaShareButton {
  display: inline-block;
  margin: 0 2px;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.all-icons input {
  border: 0;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.places-buttons .btn {
  margin-bottom: 30px;
}


.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.fixed-plugin {
  position: absolute;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 10px;
  padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  max-height: 175px;
  margin: 0 auto;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-white {
  background-color: #ffffff;
}

.fixed-plugin .badge-black {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-azure {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-green {
  background-color: #87cb16;
}

.fixed-plugin .badge-orange {
  background-color: #ffa534;
}

.fixed-plugin .badge-purple {
  background-color: #9368e9;
}

.fixed-plugin .badge-red {
  background-color: #fb404b;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 5px 2px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  text-align: center;
}

.fixed-plugin .pro-title {
  margin: 10px 0 5px 0;
  text-align: center;
}

.fixed-plugin #sharrreTitle {
  text-align: center;
  padding: 10px 0;
  height: 50px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}

.fixed-plugin .adjustments-line .switch {
  float: right;
}

.fixed-plugin .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  max-height: 86px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
  background-color: transparent;
}

.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown-menu > li > a img {
  margin-top: auto;
}

.fixed-plugin .btn-social {
  width: 50%;
  display: block;
  width: 48%;
  float: left;
  font-weight: 600;
}

.fixed-plugin .btn-social i {
  margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
  margin-right: 2%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  opacity: 1;
}

@media (min-width: 992px) {
  .fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 27px;
    opacity: 0;

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }

  .fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
  }

  .fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -15px;
  }

  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  .fixed-plugin {
    top: 300px;
  }
}

@media (max-width: 991px) {
  .fixed-plugin .dropdown-menu {
    right: 60px;
    width: 220px;
  }

  .fixed-plugin .dropdown-menu li {
    width: 50%;
  }

  .fixed-plugin li.adjustments-line,
  .fixed-plugin li.header-title,
  .fixed-plugin li.button-container {
    width: 100%;
    height: 55px;
    min-height: inherit;
  }

  .fixed-plugin .adjustments-line .switch {
    float: right;
    margin: 0 0px;
  }

  .fixed-plugin li.header-title {
    height: 40px;
  }

  .fixed-plugin .dropdown .dropdown-menu {
    top: -170px;
  }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}

.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}

.btn-social.btn-round {
  padding: 9px 10px;
}

.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}

.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}

.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}

.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}

.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.all-icons input {
  border: 0;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;

  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}

.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}

.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

.gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fcfcfc),
    color-stop(100%, #eee)
  );
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}

.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #eee),
    color-stop(100%, #ddd)
  );
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}

.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.gh-count {
  position: relative;
  display: none;
  /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}

.gh-count:hover,
.gh-count:focus {
  color: #4183c4;
}

.gh-count:before,
.gh-count:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}

.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}

.github-btn-large {
  height: 30px;
}

.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}

.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}

.github-btn-large .gh-count {
  margin-left: 6px;
}

.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}

.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}

.typography-line span {
  bottom: 10px;
  color: #9a9a9a;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  left: 5px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.typography-line {
  padding-left: 15%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.logo-img {
  height: 80px;
}

.sidebar .logo-img img {
  max-width: 100% !important;
  max-height: 100% !important;
}

.logo {
  justify-content: center !important;
}

a.simple-text.font-16 {
  display: none !important;
}

.strpied-tabled-with-hover.pt-3.pb-3.card {
  border: none;
  background: transparent;
}

.register-header.card-header {
  padding: 15px 0px 0;
  background-color: transparent;
  border-bottom: none !important;
}

.register-header.card-header h4.card-title {
  font-size: 30px;
  font-weight: 600;
}

.strpied-tabled-with-hover.card {
  border: none !important;
  border-radius: 10px;
}

.table-full-width.table-responsive.card-body {
  padding: 20px !important;
}

body .card {
  border: none;
  border-radius: 10px;
}

.card-header:first-child {
  border-radius: 10px !important;
}

.pr-1.col-md-4 .form-control {
  padding: 8px 8px !important;
  height: 40px !important;
}

.ant-table {
  overflow-x: auto;
}

.add-button:hover {
  background-color: #4183c4;
  color: #fff !important;
  border-color: #fff !important;
}

/* span.ant-select-selection-search {
  display: none;
} */
.search-wrapper .anticon svg {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 0px !important;
  inset-inline-end: 0px !important;
}

.border-lighty.ml-2.search-wrapper.input-group {
  padding: 0px 10px !important;
}

.form-control {
  padding: 8px 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ant-select {
  width: auto !important;
}

button.mt-3.mb-4.btn.btn-three.btn.btn-primary i.fas.fa-times {
  color: #ff4a55;
  border: none;
}

button.mt-3.mb-4.btn.btn-three.btn.btn-primary i.fas.fa-edit {
  color: #fff;
  border: none;
}

button.mt-3.mb-4.btn.btn-three.btn.btn-primary i.fas.fa-edit:hover {
  color: #00bbff;
  background-color: #fff;
  border: 1px solid #00bbff;
}

button.mt-3.mb-4.btn.btn-three.btn.btn-primary i.fas.fa-edit :hover {
  color: #fff;
  background: #00bbff;
  border: none;
}

button.btn.btn-three.btn.btn-primary {
  border: none;
}

.give-back-btn .btn:disabled,
.btn[disabled],
.btn.disabled {
  background-color: transparent !important;
}

button.mt-3.mb-4.btn.btn-three.btn.btn-primary {
  font-size: 20px;
  padding: 8px 5px;
}

.ant-table-cell,
tr th.ant-table-cell {
  text-align: left !important;
  line-height: 1.2;
}

span.ant-table-column-title {
  color: #333333;
}

th.ant-table-cell {
  color: #333333 !important;
}

.action-buttons {
  justify-content: flex-start !important;
}

.ant-select-single {
  height: 40px !important;
}

.search-wrapper.input-group {
  height: 40px !important;
  align-items: center !important;
}

.ant-select-single {
  margin-left: 0.5rem !important;
}

.user-fields-wrapper .form-control {
  height: 40px !important;
  border-radius: 6px;
}

.form-field div {
  width: 100% !important;
}

.ant-picker {
  height: 40px !important;
}

.ant-select.ant-select-in-form-item {
  width: auto !important;
  display: flex;
  flex-direction: column;
}

.ant-select-single {
  margin-left: 0 !important;
}

.ant-form-item.css-dev-only-do-not-override-pr0fja {
  margin-bottom: 0px !important;
}

.user-fields-wrapper.col .form-control {
  padding: 8px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88) !important;
  font-weight: normal;
}

.ant-select .ant-select-selection-item {
  font-weight: normal !important;
}

legend {
  width: auto !important;
}

.add-button {
  padding: 8px 18px 8px 12px;
}

button.mt-3.mb-4.btn.btn-three.btn.btn-primary,
button.btn-simple.btn-link.p-1.btn.btn-info,
button.btn-simple.btn-link.p-1.btn.btn-danger {
  font-size: 16px;
  padding: 6px 10px;
}

button.mt-3.mb-4.btn.btn-three.edit.btn.btn-primary,
button.btn-simple.btn-link.p-1.btn.btn-info {
  background-color: #87cb16;
}

button.mt-3.mb-4.btn.btn-three.delete.btn.btn-primary,
button.btn-simple.btn-link.p-1.btn.btn-danger {
  color: #fff;
  background-color: #fb404b;
  margin-left: 5px;
}

button.btn-fill.pull-right.mt-3.add-user-button.btn.btn-info {
  margin-bottom: 20px;
}

button.btn-fill.pull-right.mt-3.mr-2.reset-user-button.btn.btn-secondary {
  margin-bottom: 20px;
}

button.btn-simple.btn-link.p-1.btn.btn-info {
  color: #fff !important;
}
.edit.reset i {
  color: #fff;
}
.edit.reset {
  background-color: #007bff !important;
  color: #fff;
}
/* button.mt-3.mb-4.btn.btn-three.edit.btn.btn-primary:hover{
  background-color: #609905 !important;
} */
button.mt-3.mb-4.btn.btn-three.delete.btn.btn-primary:hover {
  color: #fff !important;
}

button.mt-3.mb-4.btn.btn-three.give-back-btn.btn.btn-primary {
  background-color: #1fc6ea !important;
  color: #fff !important;
}

.card .table tbody td:last-child {
  padding-right: 0 !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:hover {
  color: #00bbff;
  background: #ececec;
}

span.anticon.anticon-user-add.m-1 {
  display: none;
}

.add-button {
  padding: 8px 15px 8px 15px;
}

i.fa.fa-plus {
  padding-top: 3px;
  padding-right: 8px;
  font-weight: 600;
  font-size: 18px;
}

.flex.gap-2.add-icon p {
  margin-bottom: 0 !important;
  font-size: 18px;
  font-weight: 600;
  width: max-content;
}

span.anticon.anticon-user-add {
  display: none;
}

.sidebar .nav li .nav-link p,
body > .navbar-collapse .nav li .nav-link p {
  font-size: 16px;
}

.ant-table-wrapper .ant-table-row-expand-icon {
  color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: none;
  position: relative !important;
  float: none;
  box-sizing: border-box;
  width: 0;
  height: 0;
  padding: 10px;
  line-height: 0.25;
  border: none;
  border-radius: 6px;
  transform: none;
  user-select: none;
}

.ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  vertical-align: super;
}

.ant-select-selector {
  height: 40px !important;
}

.ant-table-wrapper .ant-table-row-expand-icon::before {
  content: "\f078" !important;
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  color: #000 !important;
  top: 0 !important;
  inset-inline-end: 0 !important;
  inset-inline-start: 0 !important;
  height: 0px !important;
}
.ant-table-wrapper .ant-table-row-expand-icon::before,
.ant-table-wrapper .ant-table-row-expand-icon::after {
  position: relative !important;
}

.ant-table-wrapper .ant-table-row-expand-icon-collapsed {
  transform: rotate(90deg) !important;
  /* Initial state: no rotation (collapsed) */
}

.ant-table-wrapper .ant-table-row-expand-icon-expanded {
  transform: rotate(0deg) !important;
  /* Rotate 180 degrees when expanded */
}

.card form [class*="col-"] {
  padding: 15px;
}

span.anticon.anticon-filter {
  padding-right: 3px;
}

fieldset {
  padding: 10px 30px;
}
.add-user-button {
  background: #3f72f7 !important;
  border-color: #3f72f7;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.add-user-button:hover {
  border-color: #3472f7;
  color: #3472f7 !important;
  background: transparent !important;
}
.reset-user-button {
  border-color: #3472f7;
  color: #3472f7 !important;
  background: transparent !important;
  font-size: 18px;
  font-weight: 600;
}
.reset-user-button:hover {
  background: #3f72f7 !important;
  border-color: #3f72f7;
  color: #ffffff !important;
}
.ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: flex;
  float: none;
  justify-content: center;
  background: #ccc !important;
}
.ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: flex !important;
  float: none;
  justify-content: center;
  background: #ccc !important;
}
.ant-table-wrapper
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon-collapsed {
  display: flex !important;
  float: none;
  justify-content: center;
  background: #ccc !important;
}
button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
  display: flex !important;
  float: none;
  justify-content: center;
  background: #ccc !important;
}
button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  display: flex !important;
  float: none;
  justify-content: center;
  background: #ccc !important;
}

.ant-table-wrapper .ant-table-row-expand-icon::after {
  display: none !important;
}

.ant-table-cell {
  white-space: nowrap;
}
.password-box {
  display: flex;
  justify-content: space-between;
}
.ant-table-cell {
  max-width: 100%;
  text-overflow: ellipsis;
}

.password-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passwordTextCopy {
  max-width: 80%;
  /* Adjust the percentage based on your needs */
  overflow: hidden;
  text-overflow: ellipsis;
}

.showPasswordButton {
  margin-left: 8px;
  /* Adjust the margin based on your needs */
}

a.ml-2.showPasswordButton {
  margin-left: 0 !important;
}
.user-fields-wrapper .showPasswordButton {
  border: 2px solid #3472f7 !important;
  background-color: #ffffff;
  padding: 6px 12px;
}
button.ms-2.mx-2.add-button.max-wid.btn.btn-primary {
  font-weight: 600 !important;
  font-size: 18px !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
}

/* td.ant-table-cell div {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

span.passwordTextCopy {
  margin-right: 20px;
}
.ant-table-wrapper .ant-table-row-expand-icon {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: none;
  position: relative !important;
  float: none;
  box-sizing: border-box;
  width: 0;
  height: 0;
  padding: 10px;
  line-height: 0.25;
  border: none;
  border-radius: 6px;
  transform: none;
  -webkit-user-select: none;
  user-select: none;
}
.ant-table-wrapper .ant-table-row-expand-icon::before,
.ant-table-wrapper .ant-table-row-expand-icon::after {
  position: relative !important;
}
.ant-form-item {
  margin-bottom: 0;
}
/**************** Outer layer **********************/
.outer-logo {
  max-width: 180px;
}

.outer-layer .form-control {
  height: 40px;
}

.outer-layer h4.mb-4.text-center {
  font-size: 30px;
  font-weight: 600;
}

.outer-layer .relative.w-full.mb-2 {
  margin-bottom: 20px !important;
}

.outer-layer .text-blueGray-600 {
  font-size: 14px;
}

.text-blueGray-400.text-center.mb-3.font-bold.pt-1 {
  margin-bottom: 0 !important;
}

/* .user-fields-wrapper.col .col-md-4 div {
  width: 100%;
} */

.user-fields-wrapper.col .row {
  padding: 0px 15px;
}

input.ms-2.mx-2.border-0.form-control {
  margin-left: 5px !important;
}

.strpied-tabled-with-hover.pt-3.pb-3.card {
  margin-bottom: 0;
}

.ant-table-wrapper .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(90deg);
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  padding-left: 15px;
}

/* Hide increment and decrement buttons in input field */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-label {
  color: #000000;
  text-transform: none;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
}

.user-fields-wrapper textarea.form-control {
  height: 100px !important;
}

.outer-layer .content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 157px);
}
.w-screen {
  width: 100vw;
}
.ant-picker {
  width: 100%;
}
.ant-select-selector {
  min-width: 150px;
}
* {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}
.checkboxDropdownOptions .ant-select-item-option-state {
  display: none;
}
.checkboxDropdownSelect .ant-select-selection-overflow {
  flex-wrap: nowrap;
}
.checkboxDropdownSelect .ant-select-selection-item-remove {
  display: none;
}

@media (max-width: 991px) {
  .mr-2.no-border.navbar-toggler {
    display: none;
  }

  .navbar-collapse {
    flex-basis: 50%;
    flex-grow: 1;
    align-items: center;
  }

  #basic-navbar-nav {
    display: block;
  }

  .ant-space-item .flex.flex-column {
    display: none;
  }

  .text-end.dropdown-menu.show {
    position: absolute;
    left: auto;
    right: 0px;
  }
}

@media (min-width: 768px) {
  /* .user-fields-wrapper .col-md-4 {
    display: flex;
    align-items: center;
  } */
}

@media screen and (max-width: 1361px) {
  .network-provider-table .filter-column {
    flex-wrap: wrap;
  }
  .network-provider-table .filter-column .search-filter {
    margin-left: 0px !important;
  }
  .sim-package-table .filter-column {
    flex-wrap: wrap;
  }
  .sim-package-table .filter-column .search-filter {
    margin-left: 0px !important;
  }
}

/* For Chrome, Safari, and newer Edge */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners of the thumb */
  border: 3px solid #f1f1f1; /* Border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}
.sidebar .sidebar-wrapper,
body > .navbar-collapse .sidebar-wrapper {
  width: 262px;
}
.sidebar {
  overflow-x: hidden; /* Hide horizontal scrolling */
}

.recommended-indicator {
  background-color: #87cb16;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 5px;
  font-size: 10px;
}

.warning-indicator {
  background-color: #ffc107;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 5px;
  font-size: 10px;
}

.checkboxDropdownOptions {
  width: 300px!important;
}
.custom-label{
  width: 100%;
}

.package-select-label{
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content!important;
}

.ant-select-dropdown .ant-select-item{
  width: auto!important;
}
.ant-select-dropdown .ant-select-item label.ant-checkbox-wrapper{
  white-space: normal;
}

.edit.reset.modem-button-in-queue{
  background-color:#c0c0c0!important;
}
.edit.reset.modem-button-in-process{
  background-color:#ADD8E6!important;
}
.edit.reset.modem-button-started,.edit.reset.modem-button-stopped{
  background-color:#0000FF!important;
}
.edit.reset.modem-button-error{
  background-color:#800000!important;
}