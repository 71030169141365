.add-button{
    width: 200px;
}
.user-status-active{
    background-color: rgb(162, 219, 135);
    padding: 10px;
    border-radius: 10px;
}
.user-status-inactive{
  background-color: rgb(241, 120, 98);
  padding: 10px;
  border-radius: 10px;
}

.dataTables_length select {
    background: none;
}

.dataTables_info, .dataTables_paginate {
    transform: scale(0.8);
}

.max-wid {
    max-width: max-content;
}

.register-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  padding-top: 0px !important;
}
.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content; /* Adjust the height as needed */
}

.centered-container-full-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height as needed */
}

/* RolePermissionTable.css */
.role-permission-label {
  margin-right: 5px;
}

.role-permission-toggle {
  margin-left: 20px;
  margin-right: 5px;
}
.user-role-container .toggle-switch {
  position: relative;
  width: 64px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.user-role-container .toggle-switch-inner:before,
.user-role-container .toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 32px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}


.user-role-container .toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 4px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 6px;
  right: 33px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}


.custom-input {
  /* Add your custom styles here */
  /* For example: */
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
}
.ant-table-cell, tr th.ant-table-cell{
  text-align: center !important;
}

.action-buttons{
  flex-direction: row;
  display: flex;
  gap: 10px;
  justify-content: center !important;
  align-items: center;
  
}
.give-back-btn .btn:disabled, .btn[disabled], .btn.disabled{
  background-color: #e7e3e3;
  color: #000000;
  cursor: not-allowed;
}
.center-aligned-text{
  text-align: center;
}

/* .user-role-container{
  padding: 0 0px 20px 0px;
  border-bottom: 2px solid black;
}
.switch-secion{
  margin-bottom: 20px;
  font-size: 12px;
  margin-left: 10px;
} */
.user-role-container fieldset {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px;
  width: calc(100% - 49px);
}
.user-role-container fieldset > legend{
  padding: 0 10px;
}
.user-role-container fieldset > span{
  padding-left: 15px;
  display: flex;
  gap: 10px;
}
.user-role-container fieldset .table{
  border: 1px solid #ccc;
  margin-top: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);
}

.right-aligned-text{
  text-align: right;
}

.role-table thead tr th{
  text-transform: none;
}

@media (max-width: 767px){
  .top-table-buttons{
    justify-content: unset!important;
    padding-left: 8px;
  }
}